<template>
  <div id="dashboard">
    欢迎，
    <span style="font-weight:bold">
      {{ admin.name }}
    </span>
    <div class="card">
      <h4>基本信息</h4>
      <div class="count-list flex">
        <div class="count-item">
          <div>
            <Icon class="icon" size="31" type="md-photos"/>
          </div>
          <div>
            <div class="counts">{{ homeData.itemNum || 0 }}</div>
            <div>商品数量</div>
          </div>
        </div>
        <div class="count-item">
          <div>
            <Icon class="icon" size="31" type="md-person"/>
          </div>
          <div>
            <div class="counts">{{ homeData.memberNum || 0 }}</div>
            <div>用户数量</div>
          </div>
        </div>
        <div class="count-item" @click="navigateTo('orderList')">
          <div>
            <Icon class="icon" size="31" type="md-list"/>
          </div>
          <div>
            <div class="counts">{{ homeData.orderNum || 0 }}</div>
            <div>订单数量</div>
          </div>
        </div>
        <div class="count-item" @click="navigateTo('shopList')">
          <div>
            <Icon class="icon" size="31" type="ios-stats"/>
          </div>
          <div>
            <div class="counts">{{ homeData.storeNum || 0 }}</div>
            <div>店铺数量</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 今日，流量概括 -->
    <div class="card flow">
      <div class="flow-list flex">
        <div class="flow-item">
          <div class="flow-member">
            <div>当前在线人数</div>
            <span>
              {{ homeData.currentNumberPeopleOnline || 0 }}
            </span>
          </div>
          <div class="flow-wrapper">
            <h4>流量概括</h4>
            <div class="card flow-box flex">
              <div class="flow-box-item">
                <div>今日访客数</div>
                <div class="counts">
                  {{ homeData.todayUV || 0 }}
                </div>
              </div>
              <div class="flow-box-item">
                <div>昨日访客数</div>
                <div class="counts">
                  {{ homeData.yesterdayUV || 0 }}
                </div>
              </div>
            </div>

            <div class="flow-splice flex">
              <div class="flow-box-splice">
                <div>前七日访客数</div>
                <div class="counts">
                  {{ homeData.lastSevenUV || 0 }}
                </div>
              </div>
              <div class="flow-box-splice">
                <div>前三十日访客数</div>
                <div class="counts">
                  {{ homeData.lastThirtyUV || 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="today-box">
          <h4>今日概括</h4>
          <div class="today-list flex">
            <div class="today-item">
              <div>今日订单数</div>
              <span>{{ homeData.todayOrderNum || 0 }}</span>
            </div>
            <div class="today-item">
              <div>今日交易额</div>
              <span
              >{{ homeData.todayOrderPrice || 0 }}</span>
              <!--              <span v-else>￥0.00</span>-->
            </div>
            <div class="today-item">
              <div>今日新增店铺</div>
              <span>{{ homeData.todayStoreNum || 0 }}</span>
            </div>
            <div class="today-item">用户
              <div>今日新增用户数</div>
              <span>{{ homeData.todayMemberNum || 0 }}</span>
            </div>
            <div class="today-item">
              <div>今日上架商品数量</div>
              <span>{{ homeData.todayGoodsNum || 0 }}</span>
            </div>
            <div class="today-item">
              <div>今日新增评论</div>
              <span>{{ homeData.todayMemberEvaluation || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- chart -->
    <div class="charts flex">
      <div class="chart-item">
        <h4>流量走势</h4>
        <div id="pvChart"></div>
      </div>
      <div class="chart-item">
        <h4>交易趋势</h4>
        <div id="orderChart">
          <v-chart :option="option_column" style="height: 400px"></v-chart>
        </div>
      </div>
    </div>

    <!-- top10商品 -->
    <div class="card transform">
      <h4>周热卖商品TOP10</h4>
      <Table
          stripe
          :columns="topHotItemsColumns"
          :data="topHotItemsData"
      ></Table>
    </div>

    <!-- top10店铺 -->
    <div class="card transform">
      <h4>热卖店铺TOP10</h4>
      <Table
          stripe
          :columns="topHotStoresColumns"
          :data="topHotStoresData"
      ></Table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { homeStatistics, hotItems, hotStores, weekTrade } from "@/api"
import { centiToOne } from "@/util"
import "echarts"

import VChart from "vue-echarts"
export default {
  name: "Dashboard",
  components: {
    VChart
  },
  data() {
    return {
      homeData: "",
      topHotItemsData: [],
      topHotStoresData: [],
      orderChart: "", // 订单统计
      chartTitle: "",
      tradeChartX: [],
      tradeChartS: [],
      usedSeries: [],
      chartInitOptions: {
        renderer: "svg",
      },
      topHotItemsColumns: [
        {
          type: "index",
          width: 100,
          title: "排名",
          align: "center"
        },
        {
          title: "商品名称",
          key: "title",
        },
        {
          title: "价格",
          key: "price",
          render(h, p) {
            return h("div", centiToOne(p.row.price))
          },
        },
        {
          title: "销量",
          key: "count",
          width: 100,
          sortable: true,
        },
      ],
      topHotStoresColumns: [
        // 表格表头
        {
          type: "index",
          width: 100,
          title: "排名",
          align: "center",
        },
        {
          title: "店铺名称",
          key: "title",
        },

        {
          title: "累计销售额",
          key: "price",
          render(h, p) {
            return h("div", centiToOne(p.row.price))
          },
        },
        {
          title: "累计销量",
          key: "count",
          width: 100,
          sortable: true,
        },
      ],
    }
  },
  computed: {
    ...mapState(["admin"]),
    option_column() {
      return {
        tooltip: {},
        xAxis: {
          type: "category",
          data: this.tradeChartX,
          boundaryGap: false,
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            type: "line",
            smooth: true,
            data: this.tradeChartS,
            areaStyle: {}
          },
        ],
      }
    },
  },
  watch: {},
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      await this.getHomeData()
      await this.toHotItems()
      await this.topHotStores()
      await this.initOrderChartList()
    },
    async getHomeData() {
      try {
        const data = await homeStatistics()
        this.homeData = data
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    // top10热卖商品
    async toHotItems() {
      const res = await hotItems()
      this.topHotItemsData = res
    },

    // top10热卖店铺
    async topHotStores() {
      let res = await hotStores(this.params)
      this.topHotStoresData = res
    },
    // 实例化订单图表
    async initOrderChartList() {
      const res = await weekTrade()
      this.tradeChartX = res.xaxisData
      this.tradeChartS = res.seriesData
    },
  },
}
</script>
<style>
.card {
  margin: 10px 10px 20px;
  padding: 0 20px 20px;
  background: #fff;
  border: 1px solid #e7e7e7;

  border-radius: 0.4em;
}

h4 {
  margin: 20px 0;
  font-size: 18px;
}

.flex {
  display: flex !important;
}

.counts {
  line-height: 1;
  font-size: 21px;
}

.ivu-icon {
  margin-left: 40px;
  margin-right: 40px;
}

/deep/ .ivu-icon {
  margin-left: 40px;
  margin-right: 40px;
}

.count-item,
.todo-item {
  height: 84px;
  display: flex;

  justify-content: center;
  align-items: center;

  border-radius: 0.4em;
  flex: 1;
  font-weight: bold;
  margin-right: 20px;
}

.counts {
  margin: 4px 0;
  color: $ theme_color;
}

.todo-item,
.count-item,
.today-item,
.charts,
.transform {
  transition: 0.35s;
}

.todo-item:hover,
.count-item:hover,
.today-item:hover,
.charts:hover,
.transform:hover {
  cursor: pointer;
  transform: translateY(-10px);
  z-index: 99;
}

.count-item {
  transition: 0.35s;
  cursor: pointer;
  color: #fff;
  justify-content: flex-start;
}

.count-item:nth-of-type(1) {
  background-image: linear-gradient(109.6deg, rgba(255, 113, 113, .6) 11.2%, #ff7171 100.2%);

  box-shadow: 1px 3px 12px rgb(255 113 113 / 30%);
}

.count-item:nth-of-type(2) {
  background-image: linear-gradient(109.6deg, rgba(255, 170, 113, .6) 11.2%, #ffaa71 100.2%);

  box-shadow: 1px 3px 12px rgb(255 170 113 / 30%);
}

.count-item:nth-of-type(3) {
  background-image: linear-gradient(109.6deg, rgba(147, 181, 225, .6) 11.2%, #93b5e1 100.2%);

  box-shadow: 1px 3px 12px rgb(147 181 225 / 30%);
}

.count-item:nth-of-type(4) {
  background-image: linear-gradient(109.6deg, rgba(132, 140, 207, .6) 11.2%, #848ccf 100.2%);

  box-shadow: 1px 3px 12px rgb(132 140 207 / 30%);
}

.counts {
  line-height: 1;
  font-size: 21px;
}

.flow-box-item .counts {
  color: #ffaa71;
}


.flow-list {
  height: 330px;
}

.svg {
  width: 20px;
  height: 20px;
}

.flow-item {
  display: flex;
  flex: 1;
}

.flow {
  background: #ebebeb88 !important;
  border: none;
  padding: 0;
}

.flow-member {
  width: 200px;
  margin-right: 20px;
  font-weight: bold;

  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flow-member div {
  font-size: 18px;
  margin-top: 20px;
}

.flow-member span {
  color: #ffaa71;
  font-size: 43px;
  letter-spacing: 3px;
  margin-top: 90px;
  font-style: italic;
}

.flow-box {
  padding-top: 20px;
  width: 400px;
  justify-content: space-between;
  margin: 10px 0 20px;
}

.flow-box-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  margin: 0 20px;
}

.flow-box-item div {
  margin: 4px 0;
}

.flow-box-splice {
  background: #fff;
  width: 190px;
  margin-right: 20px;
  padding: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flow-box-splice div {
  margin: 4px 0;
}

.flow-box-splice .counts {
  color: #ffaa71;
}

.flow-box-splice:nth-last-of-type(1) {
  margin-right: 0;
}

.flow-box-splice,
.flow-member,
.card,
.today-box,
.flow-wrapper {
  border-radius: 0.4em;
  box-shadow: 1px 3px 12px hsl(0deg 0% 91% / 30%);
}

.flow-wrapper {
  background: #fff;
  padding: 0 30px;
}

.today-box {
  flex: 3;
  background: #fff;
  margin-left: 20px;
  padding: 0 30px;
}

.today-item {
  width: 30%;
  margin-bottom: 20px;
  border-radius: 0.4em;
  font-weight: bold;
  background: #ebebeb88;
  padding: 20px;
}

.today-item span {
  color: #ff5c58;
  font-size: 21px;
}

.today-item div {
  font-size: 12px;
}

.charts {
  margin: 10px 10px 20px;
  padding: 20px 20px 20px;
  background: #fff;
}

.chart-item {
  width: 48%;
  margin-right: 1%;
}

.today-list {
  justify-content: space-between;
  flex-wrap: wrap;
}

</style>
